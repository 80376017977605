import React from 'react'
import { DialogProp } from './DialogProp'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box';

const Dashboard3 = () => {
  return (
    <>
    <div>
      <h1>Arm - Kinematics</h1>
      <DialogProp/>
    </div>

    <Box sx={{ width: '100%', position: 'relative', left: 100, top: 50 }}>
      <Stack spacing={4}>
        <h2>Executive View</h2>
        <iframe width="1600" height="1300" src="https://lookerstudio.google.com/embed/reporting/4145c180-933b-4b9b-8444-450f0af48fa7/page/x98uD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Vibration & Acceleration</h2>
        <iframe width="1600" height="1200" src="https://lookerstudio.google.com/embed/reporting/a1749222-f3fc-459f-9411-ae859af1fea4/page/kpevD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Base Analysis</h2>
        <iframe width="1600" height="800" src="https://lookerstudio.google.com/embed/reporting/7fb5cfe0-a4d2-4c28-853e-4048415b771e/page/x98uD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Axis Speed Analysis</h2>
        <iframe width="1600" height="800" src="https://lookerstudio.google.com/embed/reporting/dba6d15f-2586-4875-b2d5-7d55804a4ee0/page/x98uD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Axis Movement</h2>
        <iframe width="1600" height="800" src="https://lookerstudio.google.com/embed/reporting/da83098d-3917-4071-a0f4-d612650a3cfb/page/x98uD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
      </Stack>
    </Box>


    </>
  )
}

export default Dashboard3