import React from 'react'
import { DialogProp } from './DialogProp'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const Dashboard2 = () => {
  return (
    <>
    <div>
      <h1>Robotic Arm</h1>
      <DialogProp/>
      <Box sx={{ width: '100%', position:'relative', right: 50, top: 50, left: 10}}>
        <Stack spacing={120}>
        </Stack>
      </Box>
    </div>
    
    <Box sx={{ width: '100%', position: 'relative', left: 100 }}>
      <Stack spacing={0}>
        <h2>Executive View</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/077b1684-7363-4def-9e11-8649b427c053/page/SjivD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Performance</h2>
        <iframe width="1600" height="1300" src="https://lookerstudio.google.com/embed/reporting/6a138fb1-526f-4412-980b-321dea2b5b31/page/6jNvD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
      </Stack>
    </Box>
    
    </>
  )
}

export default Dashboard2