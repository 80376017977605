import React, { useEffect, useState } from "react";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useFetchWithMsal from "../hooks/useFetchWithMsal";
import ApplicationModal from "./ApplicationModal";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { DialogProp } from "./DialogProp";
import { Dialog, ListItem } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import StorageIcon from '@mui/icons-material/Storage';
import SensorsIcon from '@mui/icons-material/Sensors';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LinkIcon from '@mui/icons-material/Link';
import AirIcon from '@mui/icons-material/Air';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import CompressIcon from '@mui/icons-material/Compress';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RecyclingIcon from '@mui/icons-material/Recycling';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FactoryIcon from '@mui/icons-material/Factory';
import VibrationIcon from '@mui/icons-material/Vibration';
import FoundationIcon from '@mui/icons-material/Foundation';
import SpeedIcon from '@mui/icons-material/Speed';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import BoltIcon from '@mui/icons-material/Bolt';

const HomePageContent = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const [open, setOpen] = useState(false);
  const [openB, setOpenB] = useState(false)
  const [openC, setOpenC] = useState(false)

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickB = () => {
    setOpenB(!openB);
  };

  const handleClickC = () => {
    setOpenC(!openC);
  };

  const applicationData = useState({
    data: [],
    isLoading: true
  })

  // pace current accessToken in state if needed as a prop
  const [token, setToken] = useState(null);

  const { result, msalError } = useMsalAuthentication(InteractionType.Popup, {
    scopes: [process.env.REACT_APP_APP_SCOPE],
  });

  useEffect(() => {
    if(result) {
      setToken(result.accessToken);
    }

  }, [result])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };
  
  const { error, execute } = useFetchWithMsal();


  return (
    <>
    <div>
      <h1>Home Dashboard</h1>
    </div>

      <Stack direction="row" spacing={5} style={{position: "relative", right: 70, top: 250}}>

        <Card sx={{ maxWidth: 345, backgroundColor: "#ff462d" }}>
            <CardMedia
              component="img"
              height="200"
              image="mde.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" color="white">
                MDE Solution
              </Typography>
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    Architecture Links
                  </ListSubheader>
                }
              >
                <ListItemButton button component="a" href="https://sales.mc.la2.space/auth/realms/standalone/protocol/openid-connect/auth?client_id=gfm-ui&redirect_uri=https%3A%2F%2Fsales.mc.la2.space%2F%23%2Fdefault%2F7xxyqs7f1oo1ovh9eswzcxly2%2Fgateways%2Fdevices&state=02bd2c2f-04a7-486d-8931-322ae4369b8a&response_mode=fragment&response_type=code&scope=openid&nonce=15f29928-1319-4dc0-bb84-c659ce9c57dc">
                  <ListItemIcon>
                    <SensorsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manufacturing Connect" />
                </ListItemButton>

                <ListItemButton button component="a" href="https://34.132.45.205/en-US/dashboard">
                  <ListItemIcon>
                    <EdgesensorHighIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Manufacturing Connect Edge" />
                </ListItemButton>

                <ListItemButton button component="a" href="https://mde.kdlabs.dev/#/data-acquisition/tags/">
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manufacturing Data Engine" />
                </ListItemButton>

              </List>
            </CardContent>
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "#ff462d" }}>
          <CardMedia
            component="img"
            height="200"
            image="water_treatment.png"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="white">
              Water Treatment Plant
            </Typography>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Data Visualizations
                </ListSubheader>
              }
            >

              <ListItem>
                <ListItemIcon>
                  <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Executive View" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <BatteryChargingFullIcon/>
                </ListItemIcon>
                <ListItemText primary="Energy Consumption" />
              </ListItem>

              
              
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Telemetric Analysis" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  
                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <AirIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Airflow" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <ThermostatAutoIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Temperature" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <CompressIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Pressure" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <GasMeterIcon/>
                    </ListItemIcon>
                    <ListItemText primary="TSS" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <GasMeterIcon />
                    </ListItemIcon>
                    <ListItemText primary="NH4" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <GasMeterIcon />
                    </ListItemIcon>
                    <ListItemText primary="NO3" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Power" />
                  </ListItem>

                  <ListItem sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <RecyclingIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Sludge" />
                  </ListItem>

                </List>
              </Collapse>

              <ListItem>
                <ListItemIcon>
                  <InvertColorsIcon/>
                </ListItemIcon>
                <ListItemText primary="Inflow vs. Outflow" />
              </ListItem>

            </List>
          </CardContent>
        <CardActions>
          <Button  variant="contained" href="/dashboard_one" startIcon={<SendIcon/>}>
            Water Treatment Analytics
          </Button>
        </CardActions>
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "#ff462d" }}>
          <CardMedia
            component="img"
            height="200"
            image="robotic_arm.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="white">
              Robotic Arm
            </Typography>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Data Visualizations
                </ListSubheader>
              }
            >

              <ListItem>
                <ListItemIcon>
                  <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Executive View" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FactoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Performance" />
              </ListItem>


            </List>
          </CardContent>
        <CardActions>
          <Button  variant="contained" href="/dashboard_two" startIcon={<SendIcon/>}>
            Robotic Arm Analytics
          </Button>
        </CardActions>
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "#ff462d" }}>
          <CardMedia
            component="img"
            height="200"
            image="arm_kinematics.jpeg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="white">
              Arm Kinematics
            </Typography>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Data Visualizations
                </ListSubheader>
              }
            >

              <ListItem>
                <ListItemIcon>
                  <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Executive View" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <VibrationIcon/>
                </ListItemIcon>
                <ListItemText primary="Vibration & Acceleration" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FoundationIcon/>
                </ListItemIcon>
                <ListItemText primary="Base Analysis" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <SpeedIcon/>
                </ListItemIcon>
                <ListItemText primary="Axis Speed" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <RunCircleIcon/>
                </ListItemIcon>
                <ListItemText primary="Axis Movement" />
              </ListItem>

            </List>
          </CardContent>
        <CardActions>
          <Button  variant="contained" href="/dashboard_three" startIcon={<SendIcon/>}>
            Arm Kinematics Analytics
          </Button>
        </CardActions>
        </Card>

        <Card sx={{ maxWidth: 345, backgroundColor: "#ff462d" }}>
          <CardMedia
            component="img"
            height="200"
            image="energy.avif"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="white">
              Arm Energy Consumption
            </Typography>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Data Visualizations
                </ListSubheader>
              }
            >

              <ListItem>
                <ListItemIcon>
                  <AdminPanelSettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Executive View" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ElectricMeterIcon/>
                </ListItemIcon>
                <ListItemText primary="Energy Usage" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ThermostatIcon/>
                </ListItemIcon>
                <ListItemText primary="Temperature Prediction" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <ThermostatAutoIcon/>
                </ListItemIcon>
                <ListItemText primary="Temperature Analysis" />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <BoltIcon/>
                </ListItemIcon>
                <ListItemText primary="Voltage & Temperature" />
              </ListItem>

            </List>
          </CardContent>
        <CardActions>
          <Button  variant="contained" href="/dashboard_four" startIcon={<SendIcon/>}>
            Energy Analytics
          </Button>
        </CardActions>
        </Card>

      </Stack>
    </>
  );
};

export default HomePageContent;
