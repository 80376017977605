import React from 'react'
import { DialogProp } from './DialogProp'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box';

const Dashboard4 = () => {
  return (
    <>
    <div>
      <h1>Arm - Energy & Temperature</h1>
      <DialogProp/>
    </div>

    <Box sx={{ width: '100%', position: 'relative', left: 100, top: 50 }}>
      <Stack spacing={4}>
        <h2>Executive View</h2>
        <iframe width="1600" height="1070" src="https://lookerstudio.google.com/embed/reporting/e96ecb46-6e24-463f-94c9-877460ad7fba/page/kpevD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Energy Usage</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/d42e9390-9bdb-4b4f-aab9-90bb5a5660dc/page/lSgvD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Temperature Prediction</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/4e38549f-4c78-49d2-bae8-14348fcf20b6/page/mZOvD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Temperature Analysis</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/4404f23c-9846-4334-ac4e-3fa5c21f2cc5/page/lSgvD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Voltage & Temperature</h2>
        <iframe width="1600" height="1200" src="https://lookerstudio.google.com/embed/reporting/7920421d-1ca1-4fb5-b7ea-3eda63cfb456/page/kpevD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
      </Stack>
    </Box>

    </>
  )
}

export default Dashboard4