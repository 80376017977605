import React, { useState, useEffect } from "react";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ ...props }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const currentAccount = instance.getActiveAccount();

  const authRequest = {
    ...loginRequest,
  };

  const onLoad = async () => {
    if (currentAccount && currentAccount.idTokenClaims["roles"]) {
      if (typeof props.roles === "undefined") {
        setIsAuthorized(true);
      } else {
        let intersection = props.roles.filter((role) =>
          currentAccount.idTokenClaims["roles"].includes(role),
        );
        if (intersection.length > 0) {
          setIsAuthorized(true);
        }
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, [instance, currentAccount]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {isAuthorized ? <>{props.children}</> : <Navigate to="/" />}
    </MsalAuthenticationTemplate>
  );
};
