import Typography from "@mui/material/Typography";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Box from "@mui/material/Box";
import * as React from "react";

import AdminPageContent from "../components/AdminPageContent";

export const AdminPage = ({ isAdmin }) => {
  return (
    <>
      <AuthenticatedTemplate>
        {isAdmin ? (
          <Box sx={{ width: "100%" }}>
            <AdminPageContent />
          </Box>
        ) : (
          <Typography variant="h6">
            Access Denied. You do not have permission to access this page.
          </Typography>
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Typography variant="h6">
          Please sign-in to obtain your Azure tenant report.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
};
