import React from 'react'
import { DialogProp } from './DialogProp';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';


const Dashboard1 = () => {
  return (
    <>
    <div>
      <h1 >Water Treatment Plant</h1>
      <DialogProp/>

    </div>

    <br/>
    
    <Box sx={{ width: '100%', position: 'relative', left: 100 }}>
      <Stack spacing={0}>
        <br/>
        <h2>Executive View</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/a964b047-83f7-43c8-bf2c-28f073866280/page/SjivD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Energy Consumption</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/0ebe4264-0b17-4f7c-a67d-6655462f9e65/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>AirFlow Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/efea6731-bb33-4074-b8cc-3c2cd75628dc/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Temperature Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/fe792459-d082-4dce-835e-d181e80f108e/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Pressure Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/97c58d30-e4da-41d9-858d-478f1897d15e/page/AmtuD" frameborder="0" style={{border:0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>TSS Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/a86221f3-7760-4582-b43c-b0042f76dbac/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>NH4 Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/bec6080e-c243-4d6e-94a5-e4107555c343/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>NO3 Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/ccdfb5fe-9b57-4838-b98f-48ddb914dd3f/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Power Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/cae4821d-40a4-4bf6-a169-4f6a9f8f0739/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Sludge Level Analysis</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/99e88153-7f06-4466-9ef9-60badcb9a871/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Inflow vs Outflow</h2>
        <iframe width="1600" height="1000" src="https://lookerstudio.google.com/embed/reporting/c2053438-73cb-409d-8a26-cc3ae481b8e1/page/xpuuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Inflow Analysis</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/82ce921a-7112-4b3e-8b82-2dda88fab3ef/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Outflow Analysis</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/1e8eaebf-4402-4480-8cb8-013a995b48a1/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>PH Analysis</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/3cd4c199-c077-4793-a471-4740106227d7/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
        <h2>Dissolved Oxygen Analysis</h2>
        <iframe width="1600" height="1100" src="https://lookerstudio.google.com/embed/reporting/19a47e0d-4e14-45a9-b2cc-9b71d530636a/page/AmtuD" frameborder="0" style={{border: 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
      </Stack>
    </Box>

    </>
  )
}

export default Dashboard1;