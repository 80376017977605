import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const DialogProp = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Serverless Function
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            console.log("submit button was clicked")
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData).entries());
            const code = formJson.name;
            console.log(code);
            handleClose();
          },
        }}
      >
        <DialogTitle>Serverless Function</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter your custom code here to create a serverless function to manage your machine configuration
            as needed
          </DialogContentText>
          {/* <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}
          <textarea id ="name" style={{position: 'relative', width: 500, height: 800, left: 25, top: 10}}>
            def serverless_function():
          </textarea>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create Cloud Function</Button>
        </DialogActions>
      </Dialog>
    </>
    )

}
