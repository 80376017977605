import * as React from "react";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
/* Icons for Drawer-Sidebar */
import Admin from "@mui/icons-material/AdminPanelSettingsOutlined";
/* Pages for Kyndryl FinOps App */
import { HomePage } from "./pages/Home";
import { AdminPage } from "./pages/Admin";
import { Logout } from "./pages/Logout";
import Dashboard1 from "./components/Dashboard1";
import Dashboard2 from "./components/Dashboard2";
import Dashboard3 from "./components/Dashboard3";
import Dashboard4 from "./components/Dashboard4";
import Dashboard5 from "./components/Dashboard5";
import Dashboard6 from "./components/Dashboard6";
import Dashboard7 from "./components/Dashboard7";
import Dashboard8 from "./components/Dashboard8";
import Dashboard9 from "./components/Dashboard9";
import Dashboard10 from "./components/Dashboard10";
import Dashboard11 from "./components/Dashboard11";

import { UserPopupState } from "./components/UserPopupState";
import CloudQueue from "@mui/icons-material/CloudQueue";
import TimelineIcon from '@mui/icons-material/Timeline';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VibrationIcon from '@mui/icons-material/Vibration';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';



/* Authentication for Azure  */
import {
  MsalProvider,
  useMsal,
  useMsalAuthentication,
  useIsAuthenticated,
} from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { ProtectedRoute } from "./components/ProtectedRoute";

// Logo resources
import kyndrylLogo from "./resources/kyndrylLogo";

import { appRoles } from "./authConfig";
import useLocalStorage from "./hooks/useLocalStorage";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Pages = ({ setUsername, setRoles, setIsAdmin, isAdmin }) => {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    scopes: [process.env.REACT_APP_APP_SCOPE],
  });


  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, {
        scopes: [process.env.REACT_APP_APP_SCOPE],
      });
    }
  }, [error]);

  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance
        .ssoSilent({
          scopes: [process.env.REACT_APP_APP_SCOPE],
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
          setRoles(response.account?.idTokenClaims["roles"]);
          setUsername(response.accountname.split(/\s+/).slice(0, 2).join(" "));
          setIsAdmin(
            response.account?.idTokenClaims["roles"].includes(appRoles.Admins),
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            console.log("Silent login failed - Interactive required");
            instance.loginPopup({
              scopes: [process.env.REACT_APP_APP_SCOPE],
            });
          }
        });
    } else {
      const currentAccount = instance.getActiveAccount();
      if (currentAccount && currentAccount.idTokenClaims["roles"]) {
        setRoles(currentAccount.idTokenClaims["roles"]);
        setUsername(currentAccount.name.split(/\s+/).slice(0, 2).join(" "));
        setIsAdmin(
          currentAccount.idTokenClaims["roles"].includes(appRoles.Admins),
        );
      }
    }
  }, [instance, isAuthenticated]);

  return (
    <Box sx={{ display: "flex" }}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<ProtectedRoute></ProtectedRoute>} />
        <Route
          path="/Logout"
          element={
            <ProtectedRoute>
              <Logout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <ProtectedRoute roles={[appRoles.Admins]}>
              <AdminPage isAdmin={isAdmin} />
            </ProtectedRoute>
          }
        />
        <Route path="/dashboard_one" element={<Dashboard1></Dashboard1>} />
        <Route path="/dashboard_two" element={<Dashboard2></Dashboard2>} />
        <Route path="/dashboard_three" element={<Dashboard3></Dashboard3>} />
        <Route path="/dashboard_four" element={<Dashboard4></Dashboard4>} />
        <Route path="/dashboard_five" element={<Dashboard5></Dashboard5>} />
        <Route path="/dashboard_six" element={<Dashboard6></Dashboard6>} />
        <Route path="/dashboard_seven" element={<Dashboard7></Dashboard7>} />
        <Route path="/dashboard_eight" element={<Dashboard8></Dashboard8>} />
        <Route path="/dashboard_nine" element={<Dashboard9></Dashboard9>} />
        <Route path="/dashboard_ten" element={<Dashboard10></Dashboard10>} />
        <Route path="/dashboard_eleven" element={<Dashboard11></Dashboard11>} />
      
      </Routes>

    </Box>
  );
};

const App = ({ msalInstance }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = useState();
  const [roles, setRoles] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  function AdminPage() {
    return (
      <ListItemButton href="/admin">
        <ListItemIcon>
          <Tooltip title="Administration">
            <Admin />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Administration" />
      </ListItemButton>
    );
  }

  function AdminPage() {
    return (
      <ListItemButton href="/admin">
        <ListItemIcon>
          <Tooltip title="Administration">
            <Admin />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Administration" />
      </ListItemButton>
    );
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ bgcolor: "#FFFFFF" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              color: "#29707A",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            sx={{ height: 30 }}
            alt="Kyndryl Logo"
            src={kyndrylLogo}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            color="#29707A"
            align="center"
            sx={{ flexGrow: 1 }}
          >
            {
            // **** Application Name Here ****
            }
            Predictive Maintenance
          </Typography>

          <div
            className="navbar-collapse collapse w-100 order-3 dual-collapse2"
            color="#29707A"
          >
            <UserPopupState username={username} roles={roles} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            width: "100%",
            maxWidth: 800,
            bgcolor: "background.paper",
            color: "#29707A",
          }}
          component="nav"
          color="#29707A"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton href="/">
            <ListItemIcon>
              <Tooltip title="Home Dashboard">
                <CloudQueue />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Home Dashboard" />
          </ListItemButton>

          <ListItemButton href="/dashboard_one">
            <ListItemIcon>
              <Tooltip title="Water Treatment">
                <WaterDropIcon/>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Water Treatment" />
          </ListItemButton>

          <ListItemButton href="/dashboard_two">
            <ListItemIcon>
              <Tooltip title="Robotic Arm">
                <PrecisionManufacturingIcon/>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Robotic Arm" />
          </ListItemButton>

          <ListItemButton href="/dashboard_three">
            <ListItemIcon>
              <Tooltip title="Arm Kinematics">
                <VibrationIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Arm Kinematics" />
          </ListItemButton>

          <ListItemButton href="/dashboard_four">
            <ListItemIcon>
              <Tooltip title="Arm Energy">
                <ThermostatIcon/>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Arm Energy" />
          </ListItemButton>


          {isAdmin ? <AdminPage /> : null}
        </List>
      </Drawer>
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
        <MsalProvider instance={msalInstance}>
          <Pages
            setUsername={setUsername}
            setRoles={setRoles}
            setIsAdmin={setIsAdmin}
            isAdmin={isAdmin}
          />
        </MsalProvider>
      </Box>
    </Box>
  );
};

export default App;
