import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Avatar from "react-avatar";
import { Typography } from "@mui/material";

export const UserPopupState = ({ username, roles }) => {
  return (
    <PopupState variant="popover" popupId="userid-popup-popover">
      {(popupState) => (
        <div>
          <Avatar
            size="50"
            color="#29707A"
            name={username}
            variant="contained"
            {...bindTrigger(popupState)}
          >
            Open Popover
          </Avatar>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography sx={{ p: 1 }}>{username}</Typography>
            <Typography sx={{ p: 1 }}>Role: {roles}</Typography>
            <Typography sx={{ p: 1 }}>
              <a className="nav-link" href="/Logout">
                Sign Out
              </a>
            </Typography>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
