import { useState, useCallback } from "react";

import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest) => {
  const [error, setError] = useState(null);

  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Popup,
    {
      scopes: [process.env.REACT_APP_APP_SCOPE],
    },
  );

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, {optional}
   * @returns JSON response
   */
  const execute = async (method, endpoint, data = null) => {
    const handleResponse = (response) => {
      return response.json().then((json) => {
        let modifiedJson = null;
        if (response.ok) {
          modifiedJson = {
            data: json,
            isError: false,
          };
        } else {
          modifiedJson = {
            error:
              method +
              " " +
              endpoint +
              " " +
              response.status +
              " (" +
              response.statusText +
              ")",
            data: [],
            isError: true,
          };
        }

        // If request failed, reject and return modified json string as error
        if (!response.ok) return Promise.reject(JSON.stringify(modifiedJson));

        // If successful, continue by returning modified json string
        return JSON.stringify(modifiedJson);
      });
    };

    if (msalError) {
      setError(msalError);
      return;
    }

    if (result) {
      try {
        const headers = new Headers();
        const bearer = `Bearer ${result.accessToken}`;
        headers.append("Authorization", bearer);

        if (data) headers.append("Content-Type", "application/json");

        let options = {
          method: method,
          headers: headers,
          body: data ? JSON.stringify(data) : null,
        };

        const promise = new Promise((resolve, reject) => {
          let result = null;
          fetch(endpoint, options)
            .then(handleResponse)
            .then((response) => JSON.parse(response))
            .then((json) => resolve(json))
            .catch((error) => {
              let modifiedJson = null;
              try {
                modifiedJson = JSON.parse(error);
              } catch (e) {
                modifiedJson = {};
                modifiedJson.error = 'net::' + 
                  "Connection Failed: " + endpoint;
                modifiedJson.isError = true;   
              }
              if (options.method === "GET") {
                modifiedJson.data = [];
              }
              result = modifiedJson;
              return Promise.resolve(JSON.stringify(modifiedJson));
            })
            .then(() => resolve(result));
        });
        return promise;
      } catch (e) {
        setError(e);
        throw e;
      }
    }
  };

  return {
    error,
    execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
  };
};

export default useFetchWithMsal;
