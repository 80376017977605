import React, { useEffect, useState } from "react";
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import BasicSnackbar from "./BasicSnackbar.jsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useFetchWithMsal from "../hooks/useFetchWithMsal.jsx";

const backendUrl = process.env.REACT_APP_BACKEND_URI;

const AdminPageContent = () => {

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  // Get execute for background useEffect fetch routines with error handling
  const { error, execute } = useFetchWithMsal();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };


  // Get token for API call.  result.accessToken contains valid JWT token
  const { result, msalError } = useMsalAuthentication(InteractionType.Popup, {
    scopes: [process.env.REACT_APP_APP_SCOPE],
  });

  return (
    <>
        <Typography variant="h6"> Adminstrative Page Sample</Typography>
        <Box
          component="main"
          sx={{ flexGrow: 1, mx: "auto" }}
        >
          <BasicSnackbar
            open={snackbar.open}
            severity={snackbar.severity}
            message={snackbar.message}
            onClose={handleClose}
            vertical="top"
            horizontal="right"
          />
        </Box>
    </>
  );
};

export default AdminPageContent;
